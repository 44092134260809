import React from 'react';
import { DataGridComponent } from '../../../common/components/table/DataGridComponent';
import { columns } from './LocationUninstallAlertReportColumns';
import { useAppSelector } from '../../../common/hooks/storeHooks';
import { LoadState } from '../../../common/store/fetched';
import { Spin } from 'antd';
import useHandleApiError from '../../../common/hooks/useHandleApiError';

const LocationUninstallAlertReport = () => {
  const reportUpdated = useAppSelector(state => state.ReportingReducer.locationUninstallAlertReportUpdated);

  const isLoadingList = reportUpdated.loadState === LoadState.InProgress;
  const data = reportUpdated.data;

  useHandleApiError(reportUpdated);

  return (
    <Spin spinning={isLoadingList}>
      <div style={{ padding: "24px" }}>
        <DataGridComponent
          toolbarContent={<h2>Detailed view of selected time period:</h2>}
          dataSource={data}
          dataSrcColumns={columns}
          summaryAvgColumns={null}
          summarySumColumns={null}
          isLoading={isLoadingList}
          storeIdentifier="LocationUninstallAlertReport"
          showPageSizeSelector
        />
      </div>
    </Spin>
  );
}

export default LocationUninstallAlertReport;
