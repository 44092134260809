import React, { useState } from 'react';
import { Button, Card, Col, Input, Row, Spin } from 'antd';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import getOtpConfig from '../../../app/actions/auth/getTotopConfig';
import verifyAndSaveMFA from '../../../app/actions/auth/verifyAndSaveMFA';
import { QRCodeCanvas } from 'qrcode.react';
import { hashHistory } from 'react-router';
import { LoadState } from '../../store/fetched';
import AppUtilityService from '../../services/AppUtilityService';
import { dismissVerifyAndSaveMFA } from '../../../app/actions/auth/slice-actions';
import WebAccessService from '../../services/WebAccessService';
import { signOut } from 'aws-amplify/auth';

const TOTPSetup = () => {
  const [code, setCode] = useState('');
  const dispatch = useAppDispatch();

  const otpSetup = useAppSelector((state) => state.AuthReducer.otpSetup);
  const verificationState = useAppSelector((state) => state.AuthReducer.verifyAndSaveMFA.loadState);
  const isMfaRequired = useAppSelector(state => state.AuthReducer.isMfaRequired.data);

  useEffect(() => {
    dispatch(getOtpConfig());
  }, [dispatch]);

  useEffect(() => {
    if (verificationState === LoadState.Success) {
      AppUtilityService.showNotificationToast('OTP Device Setup Complete', 'OTP Device Setup Complete Successfully', 'success');
      WebAccessService.navigateToFirstPermittedLink();
      dispatch(dismissVerifyAndSaveMFA());
    } else if (verificationState === LoadState.Failed) {
      AppUtilityService.showNotificationToast('OTP Device Setup Failed', 'OTP Device Setup Failed. Try again.', 'error');
      dispatch(dismissVerifyAndSaveMFA());
    }
  }, [dispatch, verificationState]);

  const handleConfirm = async () => {
    dispatch(verifyAndSaveMFA(code));
  };

  return (
    <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Col xs={{ span: 24 }} sm={{ span: 16 }} md={{ span: 16 }} lg={{ span: 10 }} xl={{ span: 6 }}>
        <Card title="Setup Authentication App" bordered={false}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {otpSetup.loadState !== LoadState.Success ? <Spin size="large" /> : <QRCodeCanvas value={otpSetup?.data.href} size={200} />}
          </div>
          <div style={{ marginBottom: 16, marginTop: 16 }}>Scan the QR Code above using your preferred authenticator app and then enter the provided one-time code below.</div>
          <Input placeholder="Enter your one-time code" maxLength={6} value={code} onChange={(e) => setCode(e.target.value)} style={{ marginBottom: 16 }} />
          <Button type="primary" block onClick={handleConfirm} style={{ marginBottom: 8 }}>
            Confirm
          </Button>
          {
            isMfaRequired
              ? (
                <Button type="link" onClick={() => signOut()} block>
                  Back to Sign In
                </Button>
              )
              : (
                <Button type="link" onClick={() => hashHistory.goBack()} block>
                  Back
                </Button>
              )
          }
        </Card>
      </Col>
    </Row>
  );
};

export default TOTPSetup;
