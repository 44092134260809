import { ICharity, IQuarterCloseGameDetails } from '../quarterClose/QuarterCloseReducer';
import { createFetched, Fetched, updateFetched } from '../../../common/store/fetched';
import { ISaturdaySalesDTO } from './SaturdayReportTypes';
import { AxiosResponse } from 'axios';

export const QUARTERCLOSE_LOAD_LOCATION_DETAILS_FAILURE = 'QUARTERCLOSE_LOAD_LOCATION_DETAILS_FAILURE';
export const QUARTERCLOSE_LOAD_LOCATION_DETAILS_REQUEST = 'QUARTERCLOSE_LOAD_LOCATION_DETAILS_REQUEST';
export const QUARTERCLOSE_LOAD_LOCATION_DETAILS_SUCCESS = 'QUARTERCLOSE_LOAD_LOCATION_DETAILS_SUCCESS';
export const QUARTERCLOSE_SET_DECOMMISSHIONED = 'QUARTERCLOSE_SET_DECOMMISSHIONED';
export const QUARTERCLOSE_SET_DEMO = 'QUARTERCLOSE_SET_DEMO';

export const LOAD_SESSIONS_LIST_REQUEST = 'LOAD_SESSIONS_LIST_REQUEST';
export const LOAD_SESSIONS_LIST_SUCCESS = 'LOAD_SESSIONS_LIST_SUCCESS';
export const LOAD_SESSIONS_LIST_FAILURE = 'LOAD_SESSIONS_LIST_FAILURE';

export const LOAD_ADNW_REQUEST = 'LOAD_ADNW_REQUEST';
export const LOAD_ADNW_SUCCESS = 'LOAD_ADNW_SUCCESS';
export const LOAD_ADNW_FAILURE = 'LOAD_ADNW_FAILURE';

export const DOWNLOAD_ADNW_UPDATED = 'DOWNLOAD_ADNW_UPDATED';

export const LOAD_SATURDAY_SALES_UPDATED = 'LOAD_SATURDAY_REPORT_UPDATED';
export const DOWNLOAD_SATURDAY_SALES_UPDATED = 'DOWNLOAD_SATURDAY_UPDATED';

export const DOWNLOAD_GAME_SUGGESTIONS_UPDATED = 'DOWNLOAD_GAME_SUGGESTIONS_UPDATED';

export interface IQuarterCloseLocationDetails {
  id: number,
  name: string,
  status: string,
  details?: IQuarterCloseLocationSetupDetails,
  charities?: ICharity[],
  distributorId: number,
  distributorName: string,
}

export interface IQuarterCloseLocationSetupDetails {
  locationSetupID: number;
  locationID: number;
  earliestStartDate: Date;
  isFinalized: boolean;
  games: IQuarterCloseGameDetails[];
  gameSelectionWindowId: number;
}

export interface ISessionsListRequestParams {
  zoneId?: number;
  locationId?: number;
  dateFrom?: string;
  dateTo?: string;
  outsidePermittedHoursOnly?: boolean;
  sortField?: 'LocationId' | 'LocationName' | 'SessionID' | 'CharityID' | 'CharityName' | 'DateFrom' | 'DateTo';
  sortDir?: 'ascend' | 'descend';
  page?: number;
  pageSize?: number;
}

export interface IAdnwReportParams {
  zoneId?: number;
  locationId?: number;
  dateFrom?: string;
  dateTo?: string;
  adjustForOpenDays?: boolean;
  avgForInstalled?: boolean;
}

export interface ISessionsListResponse {
  items: ISessionsListItem[];
  totalCount: number;
}

export interface ISessionsListItem {
  locationId: number;
  locationName: string | null;
  sessionId: number | null;
  charityId: number | null;
  charityName: string | null;
  sessionStart: string | null;
  sessionEnd: string | null;
  permittedHoursWeekDay: number | null;
  permittedHoursStart: string | null;
  permittedHoursEnd: string | null;
  dateFromInPermittedHours: boolean;
  dateToInPermittedHours: boolean;
  inPlay: string | null;
}

export interface ReportDTO {
  reportId: number;
  name: string;
  displayName: string;
  description: string;
  typeId: number | null;
  reportType: string;
  isDefault: boolean | null;
}

export interface IReportsState {
  locationDetails: IQuarterCloseLocationDetails[],
  loadingLocationDetails: boolean,
  showDecommissioned: boolean,
  showDemo: boolean,
  loadSessionsListRequestId: number | null;
  loadSessionsListSuccess: ISessionsListResponse | null;
  loadSessionsListError: unknown | null;
  reportMetricsDataCompareSuccess?: any;
  reportMetricsDataCompareError?: any;
  loadDealsChartSuccess?: any;
  loadDealsListSuccess?: any;
  exportDealsDataToExcelSuccess?: any;
  exportDealsDataToExcelError?: any;
  loadDealsChartError?: any;
  loadDealsListError?: any;
  exportDataToExcelSuccess?: any;
  exportDataToExcelError?: any;
  exportDataToExcelIPRSuccess?: any;
  exportDataToExcelIPRError?: any;
  loadGameTerminalStatusChartSuccess?: any;
  loadGameTerminalStatusChartError?: any;
  loadGameTerminalStatusListSuccess?: any;
  loadGameTerminalStatusListError?: any;
  loadReconSummaryListRequestId: number | null;
  loadReconSummaryListSuccess?: any;
  loadReconSummaryListError?: any;
  loadReconSummaryIPRListRequestId: number | null;
  loadReconSummaryIPRListSuccess?: any;
  loadReconSummaryIPRListError?: any;
  loadReconSummaryInfoRequestId: number | null;
  loadReconSummaryInfoSuccess?: any;
  loadReconSummaryInfoError?: any;
  locationReportFilters?: any;
  locationIPRReportFilters?: any;
  loadReconSummaryChartRequestId: number | null;
  loadReconSummaryChartSuccess?: any;
  loadReconSummaryChartError?: any;
  loadReconSummaryLocationRequestId: number | null;
  loadReconSummaryLocationChartSuccess?: any;
  loadReconSummaryLocationChartError?: any;
  loadReconSummaryChildListRequestId: number | null;
  loadReconSummaryChildListSuccess?: any;
  loadReconSummaryChildListError?: any;
  loadReconSummaryIPRChildListRequestId: number | null;
  loadReconSummaryIPRChildListSuccess?: any;
  loadReconSummaryIPRChildListError?: any;
  loadAdnwRequestId?: number;
  isAdnwLoading?: boolean;
  loadAdnwSuccess?: any;
  loadAdnwError?: any;
  isReportsListLoading?: boolean;
  reportsListSuccess?: ReportDTO[] | null;
  reportsListError?: any;
  downloadAdnwReport: Fetched<void>;
  downloadGameSuggestionsReport: Fetched<void>;
  downloadGameSuggestionsReportUpdated: Fetched<void>;
  saturdaySales: Fetched<AxiosResponse<ISaturdaySalesDTO[]>>;
  saturdaySalesDownload: Fetched<void>;
}

const reportsInitialState: IReportsState =
{
  locationDetails: [],
  loadingLocationDetails: true,
  showDecommissioned: false,
  showDemo: false,
  loadSessionsListRequestId: null,
  loadSessionsListSuccess: null,
  loadSessionsListError: null,
  loadReconSummaryListRequestId: null,
  loadReconSummaryInfoRequestId: null,
  loadReconSummaryChartRequestId: null,
  loadReconSummaryLocationRequestId: null,
  loadReconSummaryChildListRequestId: null,
  loadReconSummaryIPRListRequestId: null,
  loadReconSummaryIPRChildListRequestId: null,
  downloadAdnwReport: createFetched(),
  downloadGameSuggestionsReport: createFetched(),
  downloadGameSuggestionsReportUpdated: createFetched(),
  saturdaySales: createFetched(),
  saturdaySalesDownload: createFetched(),
};

const ReportsReducer = (state: IReportsState = reportsInitialState, action) => {
  switch (action.type) {
    case "LOCATION_REPORT_FILTER_UPDATE":
      return { ...state, locationReportFilters: action.payload };
    // Recon summary data related
    case "EXPORT_DATA_TO_EXCEL_REQUEST":
      return { ...state, exportDataToExcelSuccess: null, exportDataToExcelError: null };
    case "EXPORT_DATA_TO_EXCEL_SUCCESS":
      return { ...state, exportDataToExcelSuccess: action.payload, exportDataToExcelError: null };
    case "EXPORT_DATA_TO_EXCEL_FAILURE":
      return { ...state, exportDataToExcelSuccess: null, exportDataToExcelError: action.payload };

    // Recon summary data related
    case "EXPORT_DATA_TO_EXCEL_IPR_REQUEST":
        return { ...state, exportDataToExcelIPRSuccess: null, exportDataToExcelIPRError: null };
    case "EXPORT_DATA_TO_EXCEL_IPR_SUCCESS":
        return { ...state, exportDataToExcelIPRSuccess: action.payload, exportDataToExcelIPRError: null };
    case "EXPORT_DATA_TO_EXCEL_IPR_FAILURE":
        return { ...state, exportDataToExcelIPRSuccess: null, exportDataToExcelIPRError: action.payload };


    /**
     * Recon summary by date
     */
    case "LOAD_RECON_SUMMARY_CHART_REQUEST":
      return { ...state, loadReconSummaryChartSuccess: null, loadReconSummaryChartError: null, loadReconSummaryChartRequestId: action.payload.requestId };
    case "LOAD_RECON_SUMMARY_CHART_SUCCESS":
      if (action.payload.requestId !== state.loadReconSummaryChartRequestId) {
        return state;
      }
      return { ...state, loadReconSummaryChartSuccess: action.payload.data, loadReconSummaryChartError: null };
    case "LOAD_RECON_SUMMARY_CHART_FAILURE":
      if (action.payload.requestId !== state.loadReconSummaryChartRequestId) {
        return state;
      }
      return { ...state, loadReconSummaryChartSuccess: null, loadReconSummaryChartError: action.payload.errorResult };

    case "LOAD_RECON_LOCATION_SUMMARY_CHART_REQUEST":
      return { ...state, loadReconSummaryLocationChartSuccess: null, loadReconSummaryLocationChartError: null, loadReconSummaryLocationRequestId: action.payload.requestId };
    case "LOAD_RECON_LOCATION_SUMMARY_CHART_SUCCESS":
      if (action.payload.requestId !== state.loadReconSummaryLocationRequestId) {
        return state;
      }
      return { ...state, loadReconSummaryLocationChartSuccess: action.payload.data, loadReconSummaryLocationChartError: null };
    case "LOAD_RECON_LOCATION_SUMMARY_CHART_FAILURE":
      if (action.payload.requestId !== state.loadReconSummaryLocationRequestId) {
        return state;
      }
      return { ...state, loadReconSummaryLocationChartSuccess: null, loadReconSummaryLocationChartError: action.payload.errorResult };

    case "LOAD_RECON_SUMMARY_LIST_REQUEST":
      return { ...state, loadReconSummaryListSuccess: null, loadReconSummaryListError: null, loadReconSummaryListRequestId: action.payload.requestId };
    case "LOAD_RECON_SUMMARY_LIST_SUCCESS":
      if (action.payload.requestId !== state.loadReconSummaryListRequestId) {
        return state;
      }
      return { ...state, loadReconSummaryListSuccess: action.payload.data, loadReconSummaryListError: null };
    case "LOAD_RECON_SUMMARY_LIST_FAILURE":
      if (action.payload.requestId !== state.loadReconSummaryListRequestId) {
        return state;
      }
      return { ...state, loadReconSummaryListSuccess: null, loadReconSummaryListError: action.payload.errorResult };



    case "LOAD_RECON_SUMMARY_SINCE_LAST_IPR_LIST_REQUEST":
      return { ...state, loadReconSummaryIPRListSuccess: null, loadReconSummaryIPRListError: null, loadReconSummaryIPRListRequestId: action.payload.requestId };
    case "LOAD_RECON_SUMMARY_SINCE_LAST_IPR_LIST_SUCCESS":
      if (action.payload.requestId !== state.loadReconSummaryIPRListRequestId) {
        return state;
      }
      return { ...state, loadReconSummaryIPRListSuccess: action.payload.data, loadReconSummaryIPRListError: null };
    case "LOAD_RECON_SUMMARY_SINCE_LAST_IPR_LIST_FAILURE":
      if (action.payload.requestId !== state.loadReconSummaryIPRListRequestId) {
        return state;
      }
      return { ...state, loadReconSummaryIPRListSuccess: null, loadReconSummaryIPRListError: action.payload.errorResult };







    case "LOAD_RECON_SUMMARY_INFO_REQUEST":
      return { ...state, loadReconSummaryInfoSuccess: null, loadReconSummaryInfoError: null, loadReconSummaryInfoRequestId: action.payload.requestId };
    case "LOAD_RECON_SUMMARY_INFO_SUCCESS":
      if (action.payload.requestId !== state.loadReconSummaryInfoRequestId) {
        return state;
      }
      return { ...state, loadReconSummaryInfoSuccess: action.payload.data, loadReconSummaryInfoError: null };
    case "LOAD_RECON_SUMMARY_INFO_FAILURE":
      if (action.payload.requestId !== state.loadReconSummaryInfoRequestId) {
        return state;
      }
      return { ...state, loadReconSummaryInfoSuccess: null, loadReconSummaryInfoError: action.payload.errorResult };

    case "LOAD_RECON_SUMMARY_CHILD_LIST_REQUEST":
      return { ...state, loadReconSummaryChildListSuccess: null, loadReconSummaryChildListError: null, loadReconSummaryChildListRequestId: action.payload.requestId };
    case "LOAD_RECON_SUMMARY_CHILD_LIST_SUCCESS":
      if (action.payload.requestId !== state.loadReconSummaryChildListRequestId) {
        return state;
      }
      return { ...state, loadReconSummaryChildListSuccess: action.payload.data, loadReconSummaryChildListError: null };
    case "LOAD_RECON_SUMMARY_CHILD_LIST_FAILURE":
      if (action.payload.requestId !== state.loadReconSummaryChildListRequestId) {
        return state;
      }
      return { ...state, loadReconSummaryChildListSuccess: null, loadReconSummaryChildListError: action.payload.errorResult };

      case "LOAD_RECON_SUMMARY_IPR_CHILD_LIST_REQUEST":
        return { ...state, loadReconSummaryIPRChildListSuccess: null, loadReconSummaryIPRChildListError: null, loadReconSummaryIPRChildListRequestId: action.payload.requestId };
      case "LOAD_RECON_SUMMARY_IPR_CHILD_LIST_SUCCESS":
        if (action.payload.requestId !== state.loadReconSummaryIPRChildListRequestId) {
          return state;
        }
        return { ...state, loadReconSummaryIPRChildListSuccess: action.payload.data, loadReconSummaryIPRChildListError: null };
      case "LOAD_RECON_SUMMARY_IPR_CHILD_LIST_FAILURE":
        if (action.payload.requestId !== state.loadReconSummaryIPRChildListRequestId) {
          return state;
        }
        return { ...state, loadReconSummaryIPRChildListSuccess: null, loadReconSummaryIPRChildListError: action.payload.errorResult };


    /**
     * Game terminal status
     */
    case "LOAD_GAME_TERMINAL_STATUS_CHART_REQUEST":
      return { ...state, loadGameTerminalStatusChartSuccess: null, loadGameTerminalStatusChartError: null };
    case "LOAD_GAME_TERMINAL_STATUS_CHART_SUCCESS":
      return { ...state, loadGameTerminalStatusChartSuccess: action.payload, loadGameTerminalStatusChartError: null };
    case "LOAD_GAME_TERMINAL_STATUS_CHART_FAILURE":
      return { ...state, loadGameTerminalStatusChartSuccess: null, loadGameTerminalStatusChartError: action.payload };

    case "LOAD_GAME_TERMINAL_STATUS_LIST_REQUEST":
      return { ...state, loadGameTerminalStatusListSuccess: null, loadGameTerminalStatusListError: null };
    case "LOAD_GAME_TERMINAL_STATUS_LIST_SUCCESS":
      return { ...state, loadGameTerminalStatusListSuccess: action.payload, loadGameTerminalStatusListError: null };
    case "LOAD_GAME_TERMINAL_STATUS_LIST_FAILURE":
      return { ...state, loadGameTerminalStatusListSuccess: null, loadGameTerminalStatusListError: action.payload };

    /**
     * Load deals avaiable
     */
    case "LOAD_DEALS_CHART_REQUEST":
      return { ...state, loadDealsChartSuccess: null, loadDealsChartError: null };
    case "LOAD_DEALS_CHART_SUCCESS":
      return { ...state, loadDealsChartSuccess: action.payload, loadDealsChartError: null };
    case "LOAD_DEALS_CHART_FAILURE":
      return { ...state, loadDealsChartSuccess: null, loadDealsChartError: action.payload };

    case "LOAD_DEALS_LIST_REQUEST":
      return { ...state, loadDealsListSuccess: null, loadDealsListError: null };
    case "LOAD_DEALS_LIST_SUCCESS":
      return { ...state, loadDealsListSuccess: action.payload, loadDealsListError: null };
    case "LOAD_DEALS_LIST_FAILURE":
      return { ...state, loadDealsListSuccess: null, loadDealsListError: action.payload };

    case "EXPORT_DEALS_DATA_TO_EXCEL_REQUEST":
      return { ...state, exportDealsDataToExcelSuccess: null, exportDealsDataToExcelError: null };
    case "EXPORT_DEALS_DATA_TO_EXCEL_SUCCESS":
      return { ...state, exportDealsDataToExcelSuccess: action.payload, exportDealsDataToExcelError: null };
    case "EXPORT_DEALS_DATA_TO_EXCEL_FAILURE":
      return { ...state, exportDealsDataToExcelSuccess: null, exportDealsDataToExcelError: action.payload };

    /**
     * Report metrics data compare
     */
    case "REPORT_METRICS_DATA_COMPARE_REQUEST":
      return { ...state, reportMetricsDataCompareSuccess: null, reportMetricsDataCompareError: null };
    case "REPORT_METRICS_DATA_COMPARE_SUCCESS":
      return { ...state, reportMetricsDataCompareSuccess: action.payload, reportMetricsDataCompareError: null };
    case "REPORT_METRICS_DATA_COMPARE_FAILURE":
      return { ...state, reportMetricsDataCompareSuccess: null, reportMetricsDataCompareError: action.payload };

    case "LOAD_REPORTS_LIST_REQUEST":
      return { ...state, reportsListSuccess: null, reportsListError: null, isReportsListLoading: true };
    case "LOAD_REPORTS_LIST_SUCCESS":
      return { ...state, reportsListSuccess: action.payload as ReportDTO[], reportsListError: null, isReportsListLoading: false };
    case "LOAD_REPORTS_LIST_FAILURE":
      return { ...state, reportsListSuccess: null, reportsListError: action.payload, isReportsListLoading: false };

    case QUARTERCLOSE_LOAD_LOCATION_DETAILS_REQUEST:
      return { ...state, loadingLocationDetails: action.payload }

    case QUARTERCLOSE_LOAD_LOCATION_DETAILS_SUCCESS:
      return { ...state, locationDetails: action.payload }

    case QUARTERCLOSE_SET_DECOMMISSHIONED:
      return { ...state, showDecommissioned: action.payload }

    case QUARTERCLOSE_SET_DEMO:
      return { ...state, showDemo: action.payload }

    case LOAD_SESSIONS_LIST_REQUEST:
      return {
        ...state,
        loadSessionsListSuccess: null,
        loadSessionsListError: null,
        loadSessionsListRequestId: action.payload.requestId,
      };

    case LOAD_SESSIONS_LIST_SUCCESS:
      if (action.payload.requestId !== state.loadSessionsListRequestId) {
        return state;
      }
      return { ...state, loadSessionsListSuccess: action.payload.data, loadSessionsListError: null };

    case LOAD_SESSIONS_LIST_FAILURE:
      if (action.payload.requestId !== state.loadSessionsListRequestId) {
        return state;
      }
      return { ...state, loadSessionsListSuccess: null, loadSessionsListError: action.payload.error };

    case LOAD_ADNW_REQUEST:
      return {
        ...state,
        loadAdnwSuccess: null,
        loadAdnwError: null,
        loadAdnwRequestId: action.payload.requestId,
        isAdnwLoading: true,
      };

    case LOAD_ADNW_SUCCESS:
      if (action.payload.requestId !== state.loadAdnwRequestId) {
        return state;
      }
      return { ...state, loadAdnwSuccess: action.payload.data, loadAdnwError: null, isAdnwLoading: false };

    case LOAD_ADNW_FAILURE:
      if (action.payload.requestId !== state.loadAdnwRequestId) {
        return state;
      }
      return { ...state, loadAdnwSuccess: null, loadAdnwError: action.payload.error, isAdnwLoading: false };

    case DOWNLOAD_ADNW_UPDATED:
      return { ...state, downloadAdnwReport: updateFetched(state.downloadAdnwReport, action) };

    case DOWNLOAD_GAME_SUGGESTIONS_UPDATED:
      return { ...state, downloadGameSuggestionsReport: updateFetched(state.downloadGameSuggestionsReport, action) };

    case LOAD_SATURDAY_SALES_UPDATED:
      return { ...state, saturdaySales: updateFetched(state.saturdaySales, action) };

    case DOWNLOAD_SATURDAY_SALES_UPDATED:
      return { ...state, saturdaySalesDownload: updateFetched(state.saturdaySalesDownload, action) };

    default:
      return state;
  }
}

export default ReportsReducer;
