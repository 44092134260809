import * as React from "react";
import AppUtilityService from "../../../common/services/AppUtilityService";
import { Button, Card, Icon, Layout, Spin } from 'antd';
import { DataGridComponent } from '../../../common/components/table/DataGridComponent';
import AppConstants from '../../../common/AppConstants';
import { downloadAdnwReport, loadAdnwReport } from '../../actions/reports/ReportsActions';
import LocationReportsFilterContainer from '../../containers/reports/locationStatisticsReports/LocationReportsFilterContainer';
import { filters } from '../reports/locationStatisticsReports/commonFiltersConfiguration';
import {
  LocationReportsFilter,
} from '../reports/locationStatisticsReports/LocationReportsFilterComponent';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { updateBreadcrumbs } from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import { getButtonBarFilter } from '../reports/locationStatisticsReports/getButtonBarFilter';
import { LoadState } from '../../../common/store/fetched';
import { IAdnwReportParams } from '../../reducers/reports/ReportsReducer';
import { usePrevious } from '../../../common/hooks/usePrevious';

const customFilters: LocationReportsFilter[] = [
  ...filters,
  {
    name: 'adjustForOpenDays',
    component: getButtonBarFilter([
      { label: 'Active Days', value: false },
      { label: 'All Days in Range', value: true },
    ], 'Adjust for open days:'),
    defaultValue: false,
  },
  {
    name: 'avgForInstalled',
    component: getButtonBarFilter([
      { label: 'Active Terminals', value: false },
      { label: 'Installed Terminals', value: true },
    ], 'Average for:'),
    defaultValue: false,
  },
  {
    name: 'byDatePwNet',
    component: getButtonBarFilter([
      { label: 'Cash', value: false },
      { label: 'Played Won', value: true },
    ], 'Net calculation:'),
    defaultValue: false,
  },
];

export const AverageDailyNetWinPage = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(state => state.ReportsReducer.isAdnwLoading);
  const loadSuccess = useAppSelector(state => state.ReportsReducer.loadAdnwSuccess);
  const loadError = useAppSelector(state => state.ReportsReducer.loadAdnwError);
  const isDownloadInProgress = useAppSelector(
    state => state.ReportsReducer.downloadAdnwReport.loadState === LoadState.InProgress);
  const [filterParams, setFilterParams] = useState<IAdnwReportParams | null>(null);
  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    let breadcrumbsList = [
      { name: "Reports", link: '/reports' },
      { name: "Average Daily Net Win", link: null },
    ];
    var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList, null);
    dispatch(updateBreadcrumbs(breadcrumbs));
  });

  useEffect(() => {
    if (loadError != null && prevIsLoading) AppUtilityService.handleApiError(loadError);
  }, [loadError, prevIsLoading]);

  const applyFilters = ({ filterValues: { dateRange, ...filterValues }, ...apiParams }) => {
    const params = {
      ...apiParams,
      ...filterValues,
      dateFrom: AppUtilityService.formatAPIDateFilter(dateRange?.[0]),
      dateTo: AppUtilityService.formatAPIDateFilter(dateRange?.[1]),
    };
    setFilterParams(params);
    dispatch(loadAdnwReport(params));
  }

  const downloadExcel = () => dispatch(downloadAdnwReport(filterParams));

  return (
    <>
    <Card
      style={{ paddingTop: "12px" }}
      className="reports-filter-card content-card no-header-border"
      bordered={false}
      title="Average Daily Net Win"
      extra={
        <Button
          type="primary"
          loading={isDownloadInProgress}
          onClick={downloadExcel}
        >
          <Icon type="download" style={{ fontSize: "16px" }}/> Download as Excel
        </Button>
      }
    >
      <LocationReportsFilterContainer
        disableCharityFilter={true}
        disableDistributorFilter={true}
        applyFiltersCallback={applyFilters}
        filters={customFilters}
      />
    </Card>
    <div className="pg-reports-container">
      <Layout>
        <div className="container" style={{ background: '#fff' }}>
          <Spin spinning={isLoading}>
            <div style={{ padding: "24px" }}>
              <div>
                <DataGridComponent
                  toolbarContent={<h2>Detailed view of selected time period:</h2>}
                  dataSource={loadSuccess?.items}
                  dataSrcColumns={AppConstants.adnwReportColumns}
                  isLoading={isLoading}
                  summaryAvgColumns={null}
                  summarySumColumns={null}
                  showPageSizeSelector={true}
                  storeIdentifier="AverageDailyNetWinReport"
                />
              </div>
            </div>
          </Spin>
        </div>
      </Layout>
    </div>
  </>
  )
}
