import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as UserActions from '../../actions/user/UserActions';
import { UserDetailsDescriptionCardComponent } from '../../components/user/UserDetailsDescriptionCardComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    updateUserDetailsSuccess: state.UserReducer.updateUserDetailsSuccess,
    updateUserDetailsError: state.UserReducer.updateUserDetailsError,

    deleteUserSuccess: state.UserReducer.deleteUserSuccess,
    deleteUserError: state.UserReducer.deleteUserError,

    enableUserSuccess: state.UserReducer.enableUserSuccess,
    enableUserError: state.UserReducer.enableUserError,

    resetPasswordSuccess: state.UserReducer.resetPasswordSuccess,
    resetPasswordError: state.UserReducer.resetPasswordError,

    uploadUserImageSuccess: state.UserReducer.uploadUserImageSuccess,
    uploadUserImageError: state.UserReducer.uploadUserImageError,

    removeUserImageSuccess: state.UserReducer.removeUserImageSuccess,
    removeUserImageError: state.UserReducer.removeUserImageError,
  }
}

const mapDispatchToProps = (dispatch) => {
  const test = bindActionCreators(UserActions, dispatch);
  return {
    actions: {
      updateUserDetails: test.updateUserDetails,
      uploadUserImage: test.uploadUserImage,
      removeUserImage: test.removeUserImage,
      deleteUser: test.deleteUser,
      enableUser: test.enableUser,
      resetPassword: test.resetPassword,
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsDescriptionCardComponent)
