import axios, { AxiosResponse } from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';

const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

const baseUrl = process.env.REACT_APP_API_URL ?? "/api";

class ApiService {

  getData = async <T extends unknown = any>(path: string, params?: Object) => {
    const url = `${baseUrl}${path}`;
    const session = await fetchAuthSession();
    if (session) {
      headers['Authorization'] = 'Bearer ' + session.tokens?.idToken?.toString();
    }
    return await axios.get<T>(`${url}`, { headers, params });
  }

  downloadFile = async (path: string, params?: Object, data?: any) => {
    const url = `${baseUrl}${path}`;
    const session = await fetchAuthSession();
    if (session) {
      headers['Authorization'] = 'Bearer ' + session.tokens?.idToken?.toString();
      headers['Content-Type'] = 'application/json';
    }
    return await axios.post<ArrayBuffer>(`${url}`, data, { headers, params, responseType: 'blob' });
  }

  postData = async <T extends unknown = any>(
    path: string,
    data?: any,
    isMultipartFormData?: boolean,
    validateStatus?: ((status: number) => boolean),
  ) => {
    const url = `${baseUrl}${path}`;
    const session = await fetchAuthSession();
    if (session) {
      headers['Authorization'] = 'Bearer ' + session.tokens?.idToken?.toString();
      if (isMultipartFormData) {
        headers['Content-Type'] = 'multipart/form-data'
      } else {
        headers['Content-Type'] = 'application/json';
      }
    }
    return await axios.post<T>(`${url}`, data, { headers, ...(validateStatus != null ? { validateStatus } : undefined) });
  }

  updateData = async <T extends unknown = any>(path: string, params?: Object, data?: any) => {
    const url = `${baseUrl}${path}`;
    const session = await fetchAuthSession();
    if (session) {
      headers['Authorization'] = 'Bearer ' + session.tokens?.idToken?.toString();
      headers['Content-Type'] = 'application/json';
    }
    return await axios.put<T>(`${url}`, data, { headers, params });
  }

  deleteData = async <T extends unknown = any>(path: string, params?: Object, data?: any) => {
    const url = `${baseUrl}${path}`;
    const session = await fetchAuthSession();
    if (session) {
      headers['Authorization'] = 'Bearer ' + session.tokens?.idToken?.toString();
      headers['Content-Type'] = 'application/json';
    }
    return await axios.delete<T>(`${url}`, { headers, params, data });
  }
}

const apiService = new ApiService();

export function createApiCaller<T>(request: (api: ApiService) => Promise<AxiosResponse<T>>): () => Promise<T> {
  return async () => {
    const result = await request(apiService);
    return result.data;
  }
}

export default apiService;
