import { usePrevious } from './usePrevious';
import { Fetched, LoadState } from '../store/fetched';
import { useEffect } from 'react';
import AppUtilityService from '../services/AppUtilityService';

const useHandleApiError = <T>(fetched: Fetched<T>) => {
  const prevFetched = usePrevious(fetched);

  useEffect(
    () => {
      if (fetched.loadState === LoadState.Failed && prevFetched?.loadState === LoadState.InProgress)
        AppUtilityService.handleApiError(fetched.failureReason.error);
    },
    [fetched, prevFetched]);
};

export default useHandleApiError;
