import { Badge, Card } from 'antd';
import * as React from "react";
import AppUtilityService from "../../../common/services/AppUtilityService";
import { LoadState } from '../../../common/store/fetched';
import { InvoiceListTableComponent } from './InvoiceListTableComponent';
import TextFilter from './TextFilter';
import switchFilterFactory from './SwitchFilterFactory';
import StatusFilter from './StatusFilter';
import { LocationReportsFilter } from '../reports/locationStatisticsReports/LocationReportsFilterComponent';
import { LocationReportFilters } from '../reports/Reports';
import { InvoiceStatus } from '../../reducers/billing/billing.models';
import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { updateBreadcrumbs } from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import { getInvoices } from '../../actions/billing/getInvoices';
import { setInvoiceListParams } from '../../actions/billing/slice-actions';
import DateRangeFilter from '../../../common/components/filters/DateRangeFilter';
import moment from 'moment';
import InvoiceFilterContainer from './InvoiceFilterContainer';
import { Link } from 'react-router';
import WebAccessService from '../../../common/services/WebAccessService';

const filters: LocationReportsFilter[] = [
  { name: 'invoiceNo', component: TextFilter, customPlaceHolderText: 'Search by invoice #' },
  { name: 'status', component: StatusFilter, defaultValue: InvoiceStatus.PendingReview },
  { name: 'withValidationIssuesOnly', component: switchFilterFactory('With issues only') },
  {
    name: 'dateRange',
    component: DateRangeFilter,
    defaultValue: [
      moment().subtract(29, 'day').startOf('day'),
      moment().endOf('day'),
    ],
  },
];

const BillingPage = () => {
  const dispatch = useAppDispatch();
  const invoiceListParams = useAppSelector(state => state.BillingReducer.invoiceListParams);
  const invoiceList = useAppSelector(state => state.BillingReducer.invoiceList);

  useEffect(
    () => {
      if (invoiceListParams.dateFrom && invoiceListParams.dateTo) {
        dispatch(getInvoices());
      }
    },
    [
      dispatch,
      invoiceListParams.zoneId,
      invoiceListParams.locationId,
      invoiceListParams.invoiceNo,
      invoiceListParams.status,
      invoiceListParams.withValidationIssuesOnly,
      invoiceListParams.dateFrom,
      invoiceListParams.dateTo,
      invoiceListParams.pageNo,
      invoiceListParams.pageSize,
      invoiceListParams.sortBy,
      invoiceListParams.sortAsc,
    ]);

  useEffect(
    () => {
      const breadcrumbsList = [
        { name: 'Billing', link: null },
      ];
      const hasModifyPermission = WebAccessService.hasPermissionToAccess('Invoices', 'Modify');
      const configurationLink = hasModifyPermission
        ? (
          <span className="font-12 ml-8">
            <Link className="link-primary" to="/billing/configuration">Configuration</Link>
          </span>
        )
        : null;
      const extraContent = invoiceList.loadState === LoadState.Success
        ? (
          <>
            <span className="font-12 t-grey-dark ml-8 mr-8">
              <Badge dot className="mr-8" style={{ top: '-1px', background: '#A6ADC9' }}/>
              {invoiceList.data.total} Total
            </span>
            {configurationLink}
          </>
        )
        : null;
      const breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList, extraContent);
      dispatch(updateBreadcrumbs(breadcrumbs));
    },
    [dispatch, invoiceList]);

  const applyFilters = useCallback(
    (params: any) => {
      const filterValues = (params as LocationReportFilters).filterValues;
      dispatch(setInvoiceListParams({
        ...invoiceListParams,
        pageNo: 1,
        zoneId: params.zoneId === 0 ? undefined : params.zoneId,
        locationId: params.locationId === 0 ? undefined : params.locationId,
        dateFrom: AppUtilityService.formatAPIDateFilter(filterValues.dateRange?.[0]),
        dateTo: AppUtilityService.formatAPIDateFilter(filterValues.dateRange?.[1]),
        invoiceNo: filterValues.invoiceNo ? filterValues.invoiceNo.toString() : undefined,
        withValidationIssuesOnly: !!filterValues.withValidationIssuesOnly,
        status: filterValues.status === ''
          ? undefined
          : InvoiceStatus[filterValues.status as keyof typeof InvoiceStatus],
      }));
    },
    [dispatch, invoiceListParams]);

  return (
    <>
      <Card
        style={{ paddingTop: '12px' }}
        className="reports-filter-card content-card no-header-border"
        bordered={false}
      >
        <InvoiceFilterContainer
          disableCharityFilter
          disableDistributorFilter
          filters={filters}
          applyFiltersCallback={applyFilters}
        />
      </Card>
      <InvoiceListTableComponent/>
    </>
  );
}

export default BillingPage;
