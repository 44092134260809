import * as React from 'react';
import { hashHistory } from 'react-router';
import BreadcrumbContainer from '../../containers/breadcrumbs/BreadcrumbContainer';
import { NotificationDrawerComponent } from './NotificationDrawerComponent';
import SessionService from '../../services/SessionService';
import { signOut } from 'aws-amplify/auth';
import { Layout, Row, Col, Avatar, Dropdown, Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const { Header } = Layout;

export class HeaderComponent extends React.Component<IHeaderProps, IHeaderState> {
  constructor(props: IHeaderProps) {
    super(props);
    this.state = {
      isNotificationsVisible: false,
    };
  }

  render() {
    const menu = (
      <Menu>
        <Menu.Item onClick={this.logout}>
          <a target="_blank">Logout</a>
        </Menu.Item>
        <Menu.Item onClick={this.changePassword}>
          <a target="_blank">Change Password</a>
        </Menu.Item>
        <Menu.Item onClick={this.setupMfa}>
          <a target="_blank">Setup MFA</a>
        </Menu.Item>
      </Menu>
    );
    return (
      <Header className="pg-header" style={{paddingRight:0}}>
        <Row>
          <Col xxl={9} xl={9} lg={9} md={7} sm={6} xs={8}>
            <BreadcrumbContainer />
          </Col>
          <Col xxl={15} xl={15} lg={15} md={17} sm={18} xs={16}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: '5px' }}>
            <span 
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                maxWidth: 'calc(100% - 60px)',
                minWidth: 0
              }}
              title={SessionService.UserName}
            >
              {SessionService.UserName}
            </span>
            <Dropdown trigger={['click']} overlay={menu}>
              <Avatar size="large" icon={<UserOutlined />} style={{ marginLeft: 16 }} />
            </Dropdown>
          </div>
        </Col>
        </Row>
        {this.state.isNotificationsVisible && <NotificationDrawerComponent closeNotificationsCallback={this.handleNotificationsClose} />}
      </Header>
    );
  }

  showNotifications = () => {
    this.setState({ isNotificationsVisible: true });
  };
  handleNotificationsClose = () => {
    this.setState({ isNotificationsVisible: false });
  };
  logout = async () => {
    if (SessionService.loggedIn()) {
      await signOut();
      this.props.actions.clearStore();
      hashHistory.push('/login');
    }
  };
  changePassword = () => {
    hashHistory.push('/changePassword');
  };
  setupMfa = () => {
    hashHistory.push('/otp-setup');
  };
}
