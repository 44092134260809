import * as React from "react";
import AppUtilityService from "../../../../common/services/AppUtilityService";

import ReactIframeResizer from 'react-iframe-resizer-super';
import { Card, Spin } from 'antd';
import webAccessService from "../../../../common/services/WebAccessService";
import { hashHistory } from "react-router";
import { startCase } from "lodash";
import { ISSRSReportPageProps, ISSRSReportPageState } from '../Reports';
import { fetchAuthSession } from 'aws-amplify/auth';

const SERVER_PATH = (process.env.REACT_APP_API_URL || "").replace(/\/api\/?$/, '');

export class SSRSReportPage extends React.Component<ISSRSReportPageProps, ISSRSReportPageState> {

    constructor(props: ISSRSReportPageProps) {
        super(props);
        this.state = {
            isLoading: true,
            reportUrl: null,
            reportType: this.props.params.reportType || null
        }
    }

    componentWillMount() {
        this.breadcrumbsBuild();
    }

  async componentDidMount() {
    this.checkPermissions(this.state.reportType);
    const session = await fetchAuthSession();
    this.setState({ reportUrl: `${SERVER_PATH}/Report/LoadReports?reportName=${this.state.reportType}&BearerToken=${session.tokens?.idToken?.toString()}` });
  }

  render() {
    const cardTitle = startCase(this.state.reportType);

    return (
      <>
        <Card style={{ paddingTop: "12px" }} className="reports-filter-card content-card no-header-border"
              bordered={false} title={cardTitle}>

        </Card>
        <div className="pg-ssrs-reports-container">
          {
            this.state.reportUrl == null
              ? <Spin/>
              : (
                <ReactIframeResizer
                  id="ssrsReportIframe"
                  src={this.state.reportUrl}
                  className="iframe"
                  iframeResizerOptions={{
                    checkOrigin: false,
                    resizeFrom: 'parent',
                    heightCalculationMethod: 'max',
                    onInit: this.initCallback,
                  }}
                  iframeResizerEnable={true}
                  iframeResizerUrl="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.5.8/iframeResizer.contentWindow.min.js"
                >
                </ReactIframeResizer>
              )
          }
        </div>
      </>
    )
  }

    breadcrumbsBuild = () => {
        let breadcrumbsList = [
            { name: "Reports", link: '/reports' },
            { name: "SSRS - " + startCase(this.state.reportType), link: null }
        ]
        var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList, null);
        this.props.actions.updateBreadcrumbs(breadcrumbs);
    }

    initCallback = () => {
        this.setState({ isLoading: false })
    }

    checkPermissions = (reportType: string) => {
        let moduleName: string;
        switch (reportType) {
          case 'KY_PG5100.GlobalStateStats':
          case 'KY_Quarterly':
          case 'ND_Deals_In_Play_Report':
          case 'ND_Interim_Period_Report':
          case 'ND_Monthly_Interim_Game_Audit_Report':
          case 'ND_ClosedGameSummary_Report':
          case 'ND_ClosedGameSummary_ByForm_Report':
          case 'Electronic_Pulltab_Activity':
            moduleName = 'Reports: State Reports';
            break;
          default:
            moduleName = 'Reports';
            break;
        }

        const hasPermissions = webAccessService.hasPermissionToAccess(moduleName, reportType);

        if (!hasPermissions) {
            hashHistory.push("/unauthorized-access");
        }
    }
}
