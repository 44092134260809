import { downloadOhioElectronicInstantBingoReportOperationUpdated } from './slice-actions';
import { DownloadedFile, saveFile } from '../../../common/store/fileDownload';
import apiService from '../../../common/services/ApiService';
import { createStandardFetchedAction } from '../../../common/store/fetched.slices';
import { Moment } from 'moment';
import AppConstants from '../../../common/AppConstants';

export const downloadOhioElectronicInstantBingoReport =
  (dateFrom: Moment, dateTo: Moment, locationId: number | null, columns: string[]) => createStandardFetchedAction(
    downloadOhioElectronicInstantBingoReportOperationUpdated,
    async () => {
      const result = await apiService.downloadFile(
        `/reports/invoicing/ohio-electronic-instant-bingo/excel/${dateFrom.format(AppConstants.apiDateFormat)}/${dateTo.format(AppConstants.apiDateFormat)}`,
        { locationId, columns });

      const fileName = result.headers['x-filename'];
      const file: DownloadedFile = {
        data: result.data,
        fileName,
      };
      saveFile(file);
    },
    false,
  );
