
import * as React from "react";
import { isEqual } from "lodash";
import { FormComponentProps } from 'antd/lib/form/Form'
import AppUtilityService from "../../../common/services/AppUtilityService";
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent";
import { Link } from "react-router";

import { Card, Form, Modal, Row, Col, List } from 'antd';
import WebAccessService from "../../../common/services/WebAccessService";
import AddUserToDistributorModal from './AddUserToDistributorModal';
import { UserDetail } from '../../../admin/reducers/user/user.models';
const confirm = Modal.confirm;

class DistributorLinkedUsersComponent extends React.Component<IDistributorUsersProps & FormComponentProps, IDistributorUsersState> {

  constructor(props: IDistributorUsersProps & FormComponentProps) {
    super(props);
    this.state = {
      distributorId: this.props.distributorId || null,
      distributorDetails: this.props.distributorDetail || null,
      showAddForm: false,
      isLoading: false
    }
  }

  componentDidMount() {
    //this.getDistributorUsers();
  }

  componentWillReceiveProps(nextProps: IDistributorUsersProps) {
    if (nextProps.distributorDetail && !isEqual(this.props.distributorDetail, nextProps.distributorDetail)) {
      this.setState({ distributorDetails: nextProps.distributorDetail, isLoading: false });
    }

    /**
     * add distriubtor user success/error handler
     */
    if (nextProps.addDistributorUserSuccess && !isEqual(this.props.addDistributorUserSuccess, nextProps.addDistributorUserSuccess)) {
      var distributorDetails = { ...this.state.distributorDetails };
      distributorDetails = nextProps.addDistributorUserSuccess;
      AppUtilityService.showMessageToast("User added to Distributor", "success");
      this.props.form.resetFields();
      this.setState({ distributorDetails, showAddForm: false, isLoading: false })
    }
    if (nextProps.addDistributorUserError && !isEqual(this.props.addDistributorUserError, nextProps.addDistributorUserError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.addDistributorUserError)
    }
    /**
     * Delete distributor user success/error handler
    */
    if (nextProps.deleteDistributorUserSuccess && !isEqual(this.props.deleteDistributorUserSuccess, nextProps.deleteDistributorUserSuccess)) {
      var distributorDetails = { ...this.state.distributorDetails };
      var responseData = nextProps.deleteDistributorUserSuccess;
      distributorDetails = responseData;
      AppUtilityService.showMessageToast("User removed from Distributor", "success");
      this.setState({ distributorDetails, isLoading: false })
    }
    if (nextProps.deleteDistributorUserError && !isEqual(this.props.deleteDistributorUserError, nextProps.deleteDistributorUserError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.deleteDistributorUserError)
    }
  }

  render() {

    var extraActions = WebAccessService.hasPermissionToAccess("Distributor", "Add") &&
      <span className="link-primary" onClick={this.toggleAddForm}><i className={this.state.showAddForm ? "icon-minus" : "icon-add"} /></span>


    return (
      <Card className={"no-body-padding show-border"} bordered={false}
        title="Users" extra={extraActions}
      >
        <List
          loading={this.state.isLoading}
          itemLayout="horizontal"
          dataSource={this.state.distributorDetails ? this.state.distributorDetails.users : []}
          locale={
            {
              emptyText: <EmptyStateComponent title="No Linked Users" />
            }
          }
          renderItem={(item: UserDetail) => (
            <List.Item actions={this.generateActions(item)}>
              <List.Item.Meta
                title={<span><i style={{ fontSize: '18px', verticalAlign: "sub" }} className="icon-charity-filled mr-8 t-grey-dark" />
                  <Link to={"/users/" + item.userId} className="link-primary">{item.userName}</Link>
                </span>}
                description={
                  <ul className="mini-card-details" style={{ paddingLeft: "44px" }}>
                    {
                      item.firstName && item.lastName &&
                      <li>Name: {item.firstName} {item.lastName}</li>
                    }
                    {
                      item.created &&
                      <li>Created: {item.created}</li>
                    }
                  </ul>
                }
              />

            </List.Item>
          )}
        />
        {
          this.state.showAddForm
          &&
          <AddUserToDistributorModal
            distributorId={this.state.distributorId}
            onCancel={this.toggleAddForm}
          />
        }
      </Card>
    )
  }

  toggleAddForm = () => {
    let { showAddForm } = this.state;
    if (showAddForm) {
      showAddForm = false;
    } else {
      showAddForm = true;
    }
    this.setState({ showAddForm })
  }

  generateActions = (item: UserDetail): Array<any> => {
    var actions = [];
    if (WebAccessService.hasPermissionToAccess("Distributor", "Delete")) {
      actions.push(<i className="icon-remove link-danger" onClick={() => this.deleteUser(item.userId)} />)
    }
    return actions;
  }

  deleteUser = (userId: number) => {
    var { distributorDetails } = this.state;
    confirm({
      title: 'Confirm dialog',
      content: 'Are you sure you want to remove the selected user linked to this distributor?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        this.setState({ isLoading: true })
        this.props.actions.deleteDistributorLinkedUser(distributorDetails.distributorId, userId);
      },
    });
  }

} export default Form.create<IDistributorUsersProps & FormComponentProps>()(DistributorLinkedUsersComponent);
