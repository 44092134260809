import * as React from "react";
import { PropsWithChildren, useEffect } from 'react';
import { SidebarComponent } from "./SidebarComponent";
import HeaderContainer from "../../containers/layouts/HeaderContainer";
import ErrorBoundaryWrapperContainer from "../../containers/layouts/ErrorBoundaryWrapperContainer";
import { Layout } from 'antd';
import { hashHistory } from 'react-router';
import { useAppSelector } from '../../hooks/storeHooks';

const { Content } = Layout;

interface IMainLayoutProps {
  location: Location;
}

export const MainLayout = ({ children, location }: PropsWithChildren<IMainLayoutProps>) => {
  const isMfaRequired = useAppSelector(state => state.AuthReducer.isMfaRequired.data);
  useEffect(
    () => {
      if (isMfaRequired) {
        hashHistory.push('/otp-setup');
      }
    },
    [isMfaRequired]);

  return (
    <Layout style={{ height: '100vh' }}>
      <SidebarComponent currentRouteDetails={location}/>
      <Layout className="main-layout">
        <HeaderContainer/>
        <ErrorBoundaryWrapperContainer>
          <Content className="pg-layout-content">
            {children}
          </Content>
        </ErrorBoundaryWrapperContainer>
      </Layout>
    </Layout>
  );
};
