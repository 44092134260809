import { isEmpty, get, indexOf } from 'lodash';
import axios from 'axios';
import { PermissionHelper } from './PermissionHelper';
import SessionService from './SessionService';
import AppConstants from '../AppConstants';
import { hashHistory } from 'react-router';
import { AuthSession } from 'aws-amplify/auth';

let permissionInstance = PermissionHelper.getInstance();

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const baseUrl = process.env.REACT_APP_API_URL ?? '/api';

class WebAccessService {
  loadUserPermissions = async (session: AuthSession) => {
    if (session) {
      headers['Authorization'] = 'Bearer ' + session.tokens?.idToken?.toString();
    }
    const response = await axios.get(`${baseUrl}/lookups/permission`, {
      headers: headers,
    });
    this.setUserPermissions(response.data);
  };

  setUserPermissions = (userPermissions) => {
    permissionInstance.userPermissions = userPermissions;
  };

  getUserPermissions = () => {
    return permissionInstance.userPermissions;
  };

  isAdminUser = () => {
    var role = SessionService.getRole();
    if (role === 'Admin') {
      return true;
    } else {
      return false;
    }
  };

  hasPermissionToAccess = (moduleName: string, featureName: string) => {
    var permissions: Array<Permissions> = permissionInstance.userPermissions;
    if (SessionService.getRole() === 'Admin') {
      return true;
    } else {
      if (permissions && !isEmpty(permissions)) {
        var permissionArr = get(permissions, moduleName);
        if (!isEmpty(permissionArr) && indexOf(permissionArr, featureName) !== -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  hasPermissionToAccessReports = () => {
    var permissions: Array<Permissions> = permissionInstance.userPermissions;
    if (SessionService.getRole() === 'Admin') {
      return true;
    } else {
      if (permissions && !isEmpty(permissions)) {
        // Check for any reports permissions to render the area
        var permissionArr = get(permissions, 'Reports');
        if (!isEmpty(permissionArr)) {
          return true;
        } else {
          // Check for Quarter Close View access too
          var permissionsQC = get(permissions, 'Quarter Close');
          if (!isEmpty(permissionsQC) && indexOf(permissionsQC, 'View') !== -1) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    }
  };

  getPermittedMenuItems = () => {
    var menuItems = [];
    if (AppConstants.appMenuItems && !isEmpty(AppConstants.appMenuItems)) {
      AppConstants.appMenuItems.forEach((obj) => {
        if (this.hasPermissionToAccess(obj.key, 'View')) {
          menuItems.push(obj);
        }
      });
    }
    if (SessionService.getRole() === 'Admin') {
      menuItems.push({
        key: 'Admin',
        subMenuItems: AppConstants.adminMenuItems,
      });
    }
    return menuItems;
  };

  navigateToFirstPermittedLink = () => {
    var menuItems: Array<MenuItem> = this.getPermittedMenuItems();
    if (menuItems && !isEmpty(menuItems)) {
      hashHistory.push(menuItems[0].link);
    }
  };
}

const webAccessService = new WebAccessService();
export default webAccessService;
