import React from 'react';
import { Button, Modal, Tooltip, Typography } from 'antd';
import { useAppSelector } from '../../../common/hooks/storeHooks';
import useClipboard from '../../../common/hooks/useClipboard';

export type Props = {
  userName: string
  onClose: () => void;
};

const PasswordResetResultModal = ({ userName, onClose }: Props) => {
  const { temporaryPassword, validityDays } = useAppSelector(state => state.UserReducer.resetPasswordSuccess);
  const { isClipboardAvailable, copyToClipboard } = useClipboard();

  return (
    <Modal
      visible
      title="Password Reset"
      cancelButtonProps={{ style: { display: 'none' } }}
      okText="Close"
      onCancel={onClose}
      onOk={onClose}
    >
      <p>Temporary password for <Typography.Text strong>{userName}</Typography.Text> has been set.</p>
      <p>
        The new password is: <Typography.Text code>{temporaryPassword}</Typography.Text>
        {
          isClipboardAvailable
          &&
          (
            <Tooltip title="Copy to clipboard">
              <Button
                icon="snippets"
                size="small"
                onClick={() => copyToClipboard(temporaryPassword, 'Password copied to clipboard')}
              />
            </Tooltip>
          )
        }
      </p>
      <p><Typography.Text type="secondary">Note:</Typography.Text> The password will expire after {validityDays} days.
      </p>
    </Modal>
  );
}

export default PasswordResetResultModal;
