// Common components
import { MainLayout } from './common/components/layouts/MainLayout';
import { LoginLayout } from './common/components/layouts/LoginLayout';

import LoginComponent from './common/components/user/LoginComponent';
import { UnauthorizedAccessComponent } from './common/components/layouts/UnauthorizedAccessComponent';
import ChangePasswordComponent from './common/components/user/ChangePasswordComponent';
import PageNotFoundContainer from './common/containers/layouts/PageNotFoundContainer';

// Admin components
import ZonesPageContainer from './admin/containers/zones/ZonesPageContainer';
import UserRoleListPageContainer from './admin/containers/userRole/UserRoleListPageContainer';
import UserRoleDetailsPageContainer from './admin/containers/userRole/UserRoleDetailsPageContainer';
import UserListPageContainer from './admin/containers/user/UserListPageContainer';
import UserDetailsPageContainer from './admin/containers/user/UserDetailsPageContainer';
import GameSelectionWindowContainer from './admin/containers/gameSelectionWindow/GameSelectionWindowContainer';
import GameSelectionWindowSelectGamesContainer from './admin/containers/gameSelectionWindow/GameSelectionWindowSelectGamesContainer';
import GameSelectionWindowSelectGamesCheckoutContainer from './admin/containers/gameSelectionWindow/GameSelectionWindowSelectGamesCheckoutContainer';
import GamesPage from './admin/components/games/gamesPage';

// Client components
import DashboardPageContainer from './app/containers/dashboard/DashboardPageContainer';
import LocationsListPageContainer from './app/containers/locations/LocationsListPageContainer';
import DistributorsListPageContainer from './app/containers/distributors/DistributorsListPageContainer';
import DistributorDetailsPageContainer from './app/containers/distributors/DistributorDetailsPageContainer';
import LocationDetailsPageContainer from './app/containers/locations/LocationDetailsPageContainer';
import ContactsListPageContainer from './app/containers/contacts/ContactsListPageContainer';
import ContactDetailsPageContainer from './app/containers/contacts/ContactDetailsPageContainer';
import CharityListPageContainer from './app/containers/charities/CharityListPageContainer';
import CharityDetailsPageContainer from './app/containers/charities/CharityDetailsPageContainer';
import ReportsListPage from './app/components/reports/ReportsListPage';
import ReconSummaryReportPageContainer from './app/containers/reports/locationStatisticsReports/ReconSummaryReportPageContainer';
import ReconSummaryIPRReportPageContainer from './app/containers/reports/locationStatisticsReports/ReconSummaryIPRReportPageContainer';
import GameTerminalStatusPageContainer from './app/containers/reports/locationStatisticsReports/GameTerminalStatusPageContainer';
import DealsReportPageContainer from './app/containers/reports/locationStatisticsReports/DealsReportPageContainer';
import SSRSReportContainer from './app/containers/reports/ssrs/SSRSReportContainer';
import QuarterCloseLocationStatusContainer from './app/containers/reports/locationStatisticsReports/QuarterCloseLocationStatusContainer';
import QuarterCloseLocationsContainer from './app/containers/quarterClose/QuarterCloseLocationsContainer';
import QuarterClosePayoutOptionsContainter from './app/containers/quarterClose/QuarterClosePayoutOptionsContainer';
import QuarterCloseSelectGamesContainer from './app/containers/quarterClose/QuarterCloseSelectGamesContainer';
import QuarterCloseGameDetailsContainer from './app/containers/quarterClose/QuarterCloseGameDetailsContainer';
import QuarterCloseSelectionReviewContainer from './app/containers/quarterClose/QuarterCloseSelectionReviewContainer';
import QuarterCloseConfirmationContainer from './app/containers/quarterClose/QuarterCloseConfirmationContainer';
import InvoicesPageContainer from './app/containers/invoices/InvoicesPageContainer';
import LocationSettingsPage from './app/components/locationSettings/LocationSettingsPage';
import QuarterCloseEnableTitoContainer from './app/containers/quarterClose/QuarterCloseEnableTitoContainer';
import QuarterCloseEnableW2GContainer from './app/containers/quarterClose/QuarterCloseEnableW2GContainer';

import WebAccessService from './common/services/WebAccessService';
import ForgotPasswordComponent from './common/components/user/ForgotPasswordComponent';
import LocationTypesPageContainer from './admin/containers/locationTypes/LocationTypesPageContainer';
import SessionsReportPageContainer from './app/containers/reports/locationStatisticsReports/SessionsReportPageContainer';
import NorthDakotaGameSuggestionsReportPage from './app/components/NorthDakotaGameSuggestionsReport/NorthDakotaGameSuggestionsReportPage';
import { InvoiceDetailsPage } from './app/components/billing/InvoiceDetailsPage';
import BillingPage from './app/components/billing/BillingPage';
import BillingConfiguration from './app/components/billingConfiguration/BillingConfiguration';
import OhioQuarterlyPage from './app/components/ohioQuarterlyReport/ohioQuarterlyPage';
import WeeklyNumbersPage from './app/components/weeklyNumbersReport/weklyNumbersPage';
import { AverageDailyNetWinPage } from './app/components/averageDailyNetWinPage/AverageDailyNetWinPage';
import OhioElectronicInstantBingoPage from './app/components/ohioElectronicInstantBingoReport/OhioElectronicInstantBingoPage';
import NorthDakotaInvoicesPage from './app/components/northDakotaInvoicesReport/NorthDakotaInvoicesPage';
import DistributorDealsDeliveredPage from './app/components/distributorDealsDeliveredReport/DistributorDealsDeliveredPage';
import ViewGamePage from './admin/components/games/viewGamePage';
import NorthDakotaGamesReportPage from './app/components/northDakotaGamesReport/NorthDakotaGamesReportPage';
import BillingConfigurationEditPage from './app/components/billingConfigurationEdit/BillingConfigurationEditPage';
import { SaturdaySalesPage } from './app/components/saturdayReport/SaturdaySalesPage';
import SalesTaxReportPage from './app/components/salesTaxReport/SalesTaxReportPage';
import NhLucky7MonthlyFinancialReportPage from './app/components/nhLucky7MonthlyFinancialReportPage/NhLucky7MonthlyFinancialReportPage';
import BillingConfigurationTaxRates from './app/components/billingConfiguration/BillingConfigurationTaxRates';
import TicketSalesByGameReportPage from './app/components/ticketSalesByGameReport/TicketSalesByGameReportPage';
import TicketSalesByDateReportPage from './app/components/ticketSalesByDateReport/TicketSalesByDateReportPage';
import LocationUninstallAlertReportPage from './app/components/LocationUninstallAlertReport/LocationUninstallAlertReportPage';
import SessionService from './common/services/SessionService';
import KYWS06bReportPage from './app/components/kentuckyWS06bReport/KYWS06bReportPage';
import TOTPSetup from './common/components/user/SetUpTOTP';

const validateRoute = (moduleName?: string, permissionName?: string) => (nextState, replace) => {
  if (!SessionService.loggedIn()) {
    replace('/login');
  } else if (!WebAccessService.hasPermissionToAccess(moduleName, permissionName)) {
    replace('/unauthorized-access');
  }
};

const validateAdminRoute = () => (nextState, replace) => {
  if (!SessionService.loggedIn()) {
    replace('/login');
  } else if (SessionService.getRole() !== 'Admin') {
    replace('/unauthorized-access');
  }
};

const authenticatedRoute = (nextState, replace) => {
  if (!SessionService.loggedIn()) {
    replace('/login');
  }
};

const unauthenticatedRoute = (nextState, replace) => {
  if (SessionService.loggedIn()) {
    WebAccessService.navigateToFirstPermittedLink();
  };
};

const Routes = {
  childRoutes: [
    {
      path: '/',
      indexRoute: { onEnter: (nextState, replace) => replace('/login') },
      component: LoginLayout,
      childRoutes: [
        { path: '/login', component: LoginComponent, onEnter: unauthenticatedRoute },
        { path: '/changePassword', component: ChangePasswordComponent, onEnter: authenticatedRoute },
        { path: '/forgot-password', component: ForgotPasswordComponent, onEnter: unauthenticatedRoute },
        { path: '/unauthorized-access', component: UnauthorizedAccessComponent },
        { path: '/otp-setup', component: TOTPSetup, onEnter: authenticatedRoute },
      ],
    },
    {
      component: MainLayout,
      childRoutes: [
        {
          path: '/',
          component: DashboardPageContainer,
          onEnter: validateRoute('Dashboard', 'View'),
        },
        {
          path: '/dashboard',
          component: DashboardPageContainer,
          onEnter: validateRoute('Dashboard', 'View'),
        },
        {
          path: '/locations',
          component: LocationsListPageContainer,
          onEnter: validateRoute('Location', 'View'),
        },
        {
          path: '/distributors',
          component: DistributorsListPageContainer,
          onEnter: validateRoute('Distributor', 'View'),
        },
        {
          path: '/distributors/:distributorId',
          component: DistributorDetailsPageContainer,
          onEnter: validateRoute('Distributor', 'View'),
        },
        {
          path: '/locations/:locationId',
          component: LocationDetailsPageContainer,
          onEnter: validateRoute('Location', 'View'),
        },
        {
          path: '/contacts',
          component: ContactsListPageContainer,
          onEnter: validateRoute('Contact', 'View'),
        },
        {
          path: '/contacts/:contactId',
          component: ContactDetailsPageContainer,
          onEnter: validateRoute('Contact', 'View'),
        },
        {
          path: '/charities',
          component: CharityListPageContainer,
          onEnter: validateRoute('Charity', 'View'),
        },
        {
          path: '/charities/:charityId',
          component: CharityDetailsPageContainer,
          onEnter: validateRoute('Charity', 'View'),
        },
        {
          path: '/billing',
          component: BillingPage,
          onEnter: validateRoute('Invoices', 'View'),
        },
        {
          path: '/billing/configuration',
          component: BillingConfiguration,
          onEnter: validateRoute('Invoices', 'Modify'),
        },
        {
          path: '/billing/configuration/edit/:locationId',
          component: BillingConfigurationEditPage,
          onEnter: validateRoute('Invoices', 'Modify'),
        },
        {
          path: '/billing/configuration/taxes',
          component: BillingConfigurationTaxRates,
          onEnter: validateRoute('Invoices', 'Modify'),
        },
        {
          path: '/billing/invoice/:invoiceId',
          component: InvoiceDetailsPage,
          onEnter: validateRoute('Invoices', 'View'),
        },
        {
          path: '/reports',
          component: ReportsListPage,
          onEnter: validateRoute('Reports', 'View'),
        },
        {
          path: '/reports/recon-summary/:type',
          component: ReconSummaryReportPageContainer,
          onEnter: validateRoute('Reports', 'View'), // Validations done in PageComponent based on :type
        },
        {
          path: '/reports/recon-summary-ipr/:type',
          component: ReconSummaryIPRReportPageContainer,
          onEnter: validateRoute('Reports', 'View'), // Validations done in PageComponent based on :type
        },
        {
          path: '/reports/game-terminal-status',
          component: GameTerminalStatusPageContainer,
          onEnter: validateRoute('Reports', 'ByGTStatus'),
        },
        {
          path: '/reports/deals/:type',
          component: DealsReportPageContainer,
          onEnter: validateRoute('Reports', 'View'), // Validations done in PageComponent based on :type
        },
        {
          path: '/reports/ssrs/:reportType',
          component: SSRSReportContainer,
          onEnter: validateRoute('Reports', 'View'), // Validations done in PageComponent based on :type
        },
        {
          path: '/reports/quarterClose/status',
          component: QuarterCloseLocationStatusContainer,
          onEnter: validateRoute('Reports', 'View'),
        },
        {
          path: '/reports/adnw',
          component: AverageDailyNetWinPage,
          onEnter: validateRoute('Reports', 'AdnwReport'),
        },
        {
          path: '/reports/sessions',
          component: SessionsReportPageContainer,
          onEnter: validateRoute('Reports', 'Sessions'),
        },
        {
          path: '/reports/game-suggestions',
          component: NorthDakotaGameSuggestionsReportPage,
          onEnter: validateRoute('Reports', 'GameSuggestions'),
        },
        {
          path: '/reports/ky-ws06b-report',
          component: KYWS06bReportPage,
          onEnter: validateRoute('Reports: State Reports', 'KY_WS_06b'),
        },
        {
          path: '/reports/nh-lucky7-monthly-financial-report',
          component: NhLucky7MonthlyFinancialReportPage,
          onEnter: validateRoute('Reports: State Reports', 'NH_Lucky7_Monthly_Financial_Report'),
        },
        {
          path: '/reports/saturday-sales',
          component: SaturdaySalesPage,
          onEnter: validateRoute('Reports', 'SaturdaySales'),
        },
        {
          path: '/reports/invoicing/weekly-numbers',
          component: WeeklyNumbersPage,
          onEnter: validateRoute('Invoices', 'View'),
        },
        {
          path: '/reports/invoicing/ohio-quarterly',
          component: OhioQuarterlyPage,
          onEnter: validateRoute('Invoices', 'View'),
        },
        {
          path: '/reports/invoicing/ohio-electronic-instant-bingo',
          component: OhioElectronicInstantBingoPage,
          onEnter: validateRoute('Reports: State Reports', 'View_Ohio_Electronic_Instant_Bingo'),
        },
        {
          path: '/reports/invoicing/north-dakota-invoices',
          component: NorthDakotaInvoicesPage,
          onEnter: validateRoute('Invoices', 'View'),
        },
        {
          path: '/reports/location-un-install-alert',
          component: LocationUninstallAlertReportPage,
          onEnter: validateRoute('Invoices', 'Location_Un_install_Alert_Report'),
        },
        {
          path: '/reports/ticket-sales-by-game',
          component: TicketSalesByGameReportPage,
          onEnter: validateRoute('Reports: Arrow Reports', 'Ticket_Sales_By_Game_Report'),
        },
        {
          path: '/reports/ticket-sales-by-date',
          component: TicketSalesByDateReportPage,
          onEnter: validateRoute('Reports: Arrow Reports', 'Ticket_Sales_By_Date_Report'),
        },
        {
          path: '/reports/invoicing/distributor-deals-delivered',
          component: DistributorDealsDeliveredPage,
          onEnter: validateRoute('Invoices', 'View'),
        },
        {
          path: '/reports/north-dakota-games-list',
          component: NorthDakotaGamesReportPage,
          onEnter: validateRoute('Reports: State Reports', 'View_Jurisdiction_Games_List'),
        },
        {
          path: '/reports/sales-tax',
          component: SalesTaxReportPage,
          onEnter: validateRoute('Reports', 'View'),
        },
        {
          path: '/quarterClose/locations',
          component: QuarterCloseLocationsContainer,
          onEnter: validateRoute('Quarter Close', 'View'),
        },
        {
          path: '/quarterClose/enableTito',
          component: QuarterCloseEnableTitoContainer,
          onEnter: validateRoute('Quarter Close', 'View'),
        },
        {
          path: '/quarterClose/enableW2G',
          component: QuarterCloseEnableW2GContainer,
          onEnter: validateRoute('Quarter Close', 'View'),
        },
        {
          path: '/quarterClose/payoutOptions',
          component: QuarterClosePayoutOptionsContainter,
          onEnter: validateRoute('Quarter Close', 'View'),
        },
        {
          path: '/quarterClose/selectGames',
          component: QuarterCloseSelectGamesContainer,
          onEnter: validateRoute('Quarter Close', 'View'),
        },
        {
          path: '/quarterClose/gameDetails',
          component: QuarterCloseGameDetailsContainer,
          onEnter: validateRoute('Quarter Close', 'View'),
        },
        {
          path: '/quarterClose/selectionReview',
          component: QuarterCloseSelectionReviewContainer,
          onEnter: validateRoute('Quarter Close', 'View'),
        },
        {
          path: '/quarterClose/confirmation',
          component: QuarterCloseConfirmationContainer,
          onEnter: validateRoute('Quarter Close', 'View'),
        },
        {
          path: '/invoices',
          component: InvoicesPageContainer,
          onEnter: validateRoute('Invoices', 'View'),
        },
        {
          path: '/location-settings',
          component: LocationSettingsPage,
          onEnter: validateRoute('LocationSettings', 'View'),
        },
        // Admin app routes
        {
          path: '/admin/zones',
          component: ZonesPageContainer,
          onEnter: validateAdminRoute(),
        },
        {
          path: '/admin/user-role',
          component: UserRoleListPageContainer,
          onEnter: validateAdminRoute(),
        },
        {
          path: '/admin/user-role/:roleId',
          component: UserRoleDetailsPageContainer,
          onEnter: validateAdminRoute(),
        },
        {
          path: '/admin/users',
          component: UserListPageContainer,
          onEnter: validateAdminRoute(),
        },
        {
          path: '/admin/users/:userId',
          component: UserDetailsPageContainer,
          onEnter: validateAdminRoute(),
        },
        {
          path: '/admin/locationTypes',
          component: LocationTypesPageContainer,
          onEnter: validateAdminRoute(),
        },
        {
          path: '/admin/gameSelectionWindow',
          component: GameSelectionWindowContainer,
          onEnter: validateAdminRoute(),
        },
        {
          path: '/admin/gameSelectionWindow/gameSelectionWindowSelectGames/:gameSelectionWindowId',
          component: GameSelectionWindowSelectGamesContainer,
          onEnter: validateAdminRoute(),
        },
        {
          path: '/admin/gameSelectionWindow/gameSelectionWindowSelectGames',
          component: GameSelectionWindowSelectGamesContainer,
          state: window,
          onEnter: validateAdminRoute(),
        },
        {
          path: '/admin/gameSelectionWindow/gameSelectionWindowSelectGamesCheckout',
          component: GameSelectionWindowSelectGamesCheckoutContainer,
          state: window,
          onEnter: validateAdminRoute(),
        },
        {
          path: '/admin/games',
          component: GamesPage,
          onEnter: validateAdminRoute(),
        },
        {
          path: '/admin/viewgame/:gameId',
          component: ViewGamePage,
          state: window,
          onEnter: validateAdminRoute(),
        },
        { path: '*', component: PageNotFoundContainer },
      ],
    },
  ],
};

export default Routes;
