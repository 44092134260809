import { AppDispatch } from '../../../common/store/ConfigureStore';
import { fetchDataWithActionCreator } from '../../../common/store/fetched';
import { isMfaRequiredUpdated } from './slice-actions';
import ApiService from '../../../common/services/ApiService';
import { processFailureReason } from '../../../common/store/failure';

const getIsMfaRequired =
  () =>
    async (dispatch: AppDispatch) =>
      await fetchDataWithActionCreator(
        isMfaRequiredUpdated,
        dispatch,
        async () => {
          const response = await ApiService.getData(`/account/is-mfa-required`);
          return response.data;
        },
        processFailureReason,
        false,
      );

export default getIsMfaRequired;
