
import * as React from "react";
import { isEqual } from "lodash";
import AppUtilityService from "../../../common/services/AppUtilityService";
import UserBasicDetailsFormComponent from "./UserBasicDetailsFormComponent";

import { Modal, Button } from 'antd';
import { UserDetail } from '../../reducers/user/user.models';

interface ISaveUserProps {
    userAddSuccessCallback(userDetails: UserDetail): void;
    closeSaveUserModal(): void;
    contactDetails?: Contacts;
    actions?: {
        addNewUser(data: Object): void;
    };
    addNewUserSuccess?: any;
    addNewUserError?: any;
}

interface ISaveUserState {
    userDetails: UserDetail;
}

export class SaveUserModal extends React.Component<ISaveUserProps, ISaveUserState> {

    private userBasicDetailsFormRef: any;

    constructor(props: ISaveUserProps) {
        super(props);
        this.state = {
            userDetails: null,
        }
    }

    componentWillReceiveProps(nextProps: ISaveUserProps) {
        /**
         * Add new user success/error handle
         */
        if (nextProps.addNewUserSuccess && !isEqual(this.props.addNewUserSuccess, nextProps.addNewUserSuccess)) {
            AppUtilityService.showMessageToast("User has been added successfully", "success");
            this.props.userAddSuccessCallback(nextProps.addNewUserSuccess);
        }

        if (nextProps.addNewUserError && !isEqual(this.props.addNewUserError, nextProps.addNewUserError)) {
            AppUtilityService.handleApiError(nextProps.addNewUserError);
        }

        /**
         * Update user details success/error handle
         */
        // if (nextProps.updateUserDetailsSuccess && !isEqual(this.props.updateUserDetailsSuccess, nextProps.updateUserDetailsSuccess)) {
        //     AppUtilityService.showMessageToast("User has been updated successfully", "success");
        //     this.props.userAddSuccessCallback(nextProps.updateUserDetailsSuccess);
        // }

        // if (nextProps.updateUserDetailsError && !isEqual(this.props.updateUserDetailsError, nextProps.updateUserDetailsError)) {
        //     AppUtilityService.handleApiError(nextProps.updateUserDetailsError);
        // }
    }

    render() {
        return (
            <Modal
                visible={true}
                title="Create New User"
                onOk={this.handleFormSubmit}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>Cancel</Button>,
                    <Button key="submit" type="primary" htmlType="submit" onClick={this.handleFormSubmit}>Create New User</Button>
                ]}
            >
                <UserBasicDetailsFormComponent
                    ref={(ref) => this.userBasicDetailsFormRef = ref}
                    userDetails={this.state.userDetails}
                    contactDetails={this.props.contactDetails || null}
                />
            </Modal>
        )
    }

    handleFormSubmit = (e) => {
        e.preventDefault();
        this.userBasicDetailsFormRef.validateFields((err, values) => {
            if (!err) {
                let { userDetails } = this.state;
                var formData = values;
                if (this.props.contactDetails) {
                    formData.contactId = this.props.contactDetails.id;
                    if (formData.assignLocation) {
                        formData.locationId = this.props.contactDetails.locationId;
                    }
                }
                if (!formData.lastName) {
                    formData.lastName = "";
                }
                // if (userDetails) {
                //     formData.userId = userDetails.userId;
                //     this.props.actions.updateUserDetails(userDetails.userId, formData);
                // } else {
                formData.userId = 0;
                this.props.actions.addNewUser(formData);
                // }
            }
        })
    }

    handleCancel = () => {
        this.props.closeSaveUserModal();
    }

}
