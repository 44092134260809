import React, { useCallback } from 'react';
import { DataGridComponent } from '../../../common/components/table/DataGridComponent';
import { childColumns, columns } from './TicketSalesByDateReportColumns';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { LoadState } from '../../../common/store/fetched';
import { Spin } from 'antd';
import { Filters } from './TicketSalesByDateReportFilters';
import { getTicketSalesByDateReportChild } from '../../actions/reporting/getTicketSalesByDateReportChild';
import TicketSalesByDateCharts from './TicketSalesByDateCharts';
import useHandleApiError from '../../../common/hooks/useHandleApiError';

export type Props = {
  filters: Filters;
};

const TicketSalesByDateReport = ({ filters: { endDate, startDate, jurisdictionId, siteId, organizationId } }: Props) => {
  const dispatch = useAppDispatch();

  const ticketSalesByDateReportUpdated = useAppSelector(state => state.ReportingReducer.ticketSalesByDateReportUpdated);
  const ticketSalesByDateReportChildUpdated = useAppSelector(state => state.ReportingReducer.ticketSalesByDateReportChildUpdated);

  const isLoadingList = ticketSalesByDateReportUpdated.loadState === LoadState.InProgress;
  const data = ticketSalesByDateReportUpdated.data;
  const isLoadingChildList = ticketSalesByDateReportChildUpdated.loadState === LoadState.InProgress;
  const childData = ticketSalesByDateReportChildUpdated.data;

  useHandleApiError(ticketSalesByDateReportUpdated);
  useHandleApiError(ticketSalesByDateReportChildUpdated);

  const handleRowExpanding = useCallback(
    (rowData) => {
      dispatch(getTicketSalesByDateReportChild(
        startDate, endDate, rowData.key.siteId, jurisdictionId, organizationId));
    },
    [dispatch, startDate, endDate, jurisdictionId, organizationId]);

  return (
    <Spin spinning={isLoadingList || isLoadingChildList}>
      {
        data
        &&
        <TicketSalesByDateCharts data={data}/>
      }
      <div style={{ padding: "24px" }}>
        <DataGridComponent
          toolbarContent={<h2>Detailed view of selected time period:</h2>}
          dataSource={data}
          childDataSource={childData}
          dataSrcColumns={columns}
          childDataSrcColumns={childColumns}
          summaryAvgColumns={columns}
          summarySumColumns={columns}
          childSummaryAvgColumns={childColumns}
          childSummarySumColumns={childColumns}
          isLoading={isLoadingList}
          isLoadingChild={isLoadingChildList}
          onRowExpandingCallback={handleRowExpanding}
          storeIdentifier="TicketSalesByDateReport"
          showPageSizeSelector
        />
      </div>
    </Spin>
  );
}

export default TicketSalesByDateReport;
