import { AppDispatch } from '../../../common/store/ConfigureStore';
import { fetchDataWithActionCreator } from '../../../common/store/fetched';
import { verifyAndSaveMFAUpdated } from './slice-actions';
import { processFailureReason } from '../../../common/store/failure';
import { updateMFAPreference, verifyTOTPSetup } from 'aws-amplify/auth';
import getIsMfaRequired from './getIsMfaRequired';

const verifyAndSaveMFA = (code: string) => async (dispatch: AppDispatch) =>
  await fetchDataWithActionCreator(
    verifyAndSaveMFAUpdated,
    dispatch,
    async () => {
      await verifyTOTPSetup({ code });
      await updateMFAPreference({ totp: 'PREFERRED' });
      await dispatch(getIsMfaRequired());
    },
    processFailureReason,
    false
  );

export default verifyAndSaveMFA;
