
import * as React from "react";
import { isEqual, isEmpty } from "lodash";
import { FormComponentProps } from 'antd/lib/form/Form';

import { Form, Input, Checkbox } from 'antd';
const FormItem = Form.Item;
const { TextArea } = Input;

class UserRoleDetailsFormComponent extends React.Component<IUserRoleDetailsFormProps & FormComponentProps, IUserRoleDetailsFormState> {

    constructor(props: IUserRoleDetailsFormProps & FormComponentProps) {
        super(props);
        this.state = {
            roleDetails: this.props.roleDetails || null,
        }
    }

    componentWillReceiveProps(nextProps: IUserRoleDetailsFormProps) {
        if (nextProps.roleDetails && !isEmpty(nextProps.roleDetails) && !isEqual(this.props.roleDetails, nextProps.roleDetails)) {
            this.setState({ roleDetails: nextProps.roleDetails })
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form>
                <FormItem label="Role Name">
                    {getFieldDecorator('name', {
                        initialValue: this.state.roleDetails && this.state.roleDetails.name,
                        rules: [{ required: true, message: 'Please input role name!' }],
                    })(
                        <Input placeholder="" />
                    )}
                </FormItem>
                <FormItem label="Description">
                    {getFieldDecorator('description', {
                        initialValue: this.state.roleDetails && this.state.roleDetails.description,
                    })(
                        <TextArea rows={4} placeholder="" />
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator('isMfaRequired', {
                        valuePropName: 'checked',
                        initialValue: this.state.roleDetails?.isMfaRequired ?? false
                    })(
                        <Checkbox>Is MFA required</Checkbox>
                    )}
                </FormItem>
            </Form>
        )
    }

} export default Form.create<IUserRoleDetailsFormProps & FormComponentProps>()(UserRoleDetailsFormComponent);
