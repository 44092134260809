import { AuthenticatedUser } from '../../app/reducers/auth/auth.models';

class SessionService {
  private authenticatedUser: AuthenticatedUser | null = null;

  get UserName(): string {
    return this.authenticatedUser?.userName || '';
  }

  setSessionData = (authenticatedUser: AuthenticatedUser | null) => {
    this.authenticatedUser = authenticatedUser;
  };

  loggedIn = () => {
    return this.authenticatedUser !== null;
  };

  getRole = () => {
    return this.authenticatedUser?.role || '';
  };

  getUserId = () => {
    return this.authenticatedUser?.userId || '';
  };
}
const sessionService = new SessionService();

export default sessionService;
