import { message } from 'antd';

function useClipboard() {
  const isSecureContext = window.isSecureContext;
  const hasClipboardAPI = !!navigator.clipboard;
  const isClipboardAvailable = isSecureContext && hasClipboardAPI;

  const copyToClipboard = async (text: string, successMessage: string) => {
    if (isClipboardAvailable) {
      await navigator.clipboard.writeText(text);
      message.success(successMessage);
    } else {
      message.error('Clipboard is not available');
    }
  };

  return {
    isClipboardAvailable,
    copyToClipboard,
  };
}

export default useClipboard;
